// @flow
import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

import Typography from 'components/Typography'

const Header = ({ theme, small }) => {
    const { t } = useTranslation(transDomain)
    return (
        <Typography>
            <div className={cn(styles.header, { [styles.smallHeader]: small })}>
                <div className={styles.innerContainer}>
                    <div className={styles.leftSide}>
                        <div className={styles.logo} style={{
                            '--logo-img': `url(${theme.logo})`,
                            width: theme.logoWidth,
                            height: theme.logoHeight
                        }} />

                        <h1 className={cn(styles.title, {
                            [styles.small]: small
                        })} style={{
                            color: theme.primaryColor
                        }}>
                            {t('title')}
                        </h1>

                        <span style={{
                            color: theme.secondaryColor
                        }} className={cn(styles.subtitle, {
                            [styles.small]: small
                        })}>
                            {t('subtitle')}
                        </span>
                    </div>
                </div>
                <div className={cn(styles.backgroundCover, {
                    [styles.small]: small
                })} style={{
                    '--header-img': `url(${theme.headerImage})`
                }} />
            </div>
        </Typography>
    )
}

export default withTheme(supportedThemes)(Header)
