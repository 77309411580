import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { Currency } from 'i18n/NumberFormatter'
import { withRouter } from 'react-router-dom'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'
import Typography from 'components/Typography'
import NavigatorBar from 'components/NavigatorBar'
import Steps from 'components/Steps'
import MediaQuery from 'react-responsive'
import AnimIn from 'components/AnimIn'
import ModalContainer from 'components/ModalContainer'
import Button from 'components/Button'

import transDomain from './translations/index.translations'

import { Store } from 'Store.js'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

function Step6 ({
    formRef,
    theme,
    values,
    isLoading,
    handleFieldChange,
    initialValues,
    submitted,
    history,
    location,
    match
}) {
    const { t } = useTranslation(transDomain)
    const {
        lastName,
        email,
        firstName,
        selectedOptions,
        donationType,
        mobilePhone,
        addressLine1,
        city,
        province,
        postalCode
    } = values
    const { state } = React.useContext(Store)

    const donationDesignations = state.campaign.website.donationDesignations

    const handleClick = React.useCallback(
        () => {
            history.push(`/${match.params.url}${location.search}`)
        },
        [ history, location, match ]
    )

    const currencyFormatter = Currency(state.currency, state.locale)
    const handleValidation = React.useCallback(() => {
        return formRef.current.reportValidity()
    }, [formRef])

    const amountToDonate = React.useMemo(() => {
        let total = 0
        for (const key in selectedOptions) {
            if (Object.hasOwnProperty.call(selectedOptions, key)) {
                const { amount } = selectedOptions[key]
                total += amount
            }
        }
        return total
    }, [ selectedOptions ])

    const designations = React.useMemo(() => {
        return donationDesignations.filter(designation => selectedOptions[designation])
    }, [donationDesignations, selectedOptions])

    React.useEffect(() => {
        if (values.sameAsContact) {
            handleFieldChange('sameAsContact', true, () => {
                handleFieldChange('addressBilling', values.addressLine1, () => {
                    handleFieldChange('cityBilling', values.city, () => {
                        handleFieldChange('stateBilling', values.province, () => {
                            handleFieldChange('postalCodeBilling', values.postalCode)
                        })
                    })
                })
            })
        }
    }, [handleFieldChange, values.addressLine1, values.city, values.postalCode, values.province, values.sameAsContact])

    return (
        <>
            {submitted && (
                <ModalContainer show={submitted}>
                    <div className={styles.body}>
                        <div
                            className={styles.topImage}
                            style={{
                                backgroundImage: `url(${theme.backgroundImage})`
                            }}
                        >
                            <div
                                className={styles.modalLogo}
                                style={{
                                    backgroundImage: `url(${theme.logo})`
                                }}
                            />
                        </div>
                        <div className={styles.description}>
                            <h2>Thank you, {firstName}.</h2>
                            <p>
                                Your {currencyFormatter.format(amountToDonate)} donation helps to make a difference in our students’ lives.
                            </p>
                            <Button
                                onClick={handleClick}
                                className={styles.btn}
                                style={{
                                    backgroundColor: theme.buttonBg,
                                    color: theme.buttonColor
                                }}
                            >
                                End your session
                            </Button>
                        </div>
                    </div>
                </ModalContainer>
            )}
            <MediaQuery minWidth={1023}>
                <NavigatorBar
                    step={5}
                    validateMethod={handleValidation}
                    formRef={formRef}
                    values={values}
                />
            </MediaQuery>
            <MediaQuery maxWidth={1022}>
                <Steps step={5} steps={5} />
            </MediaQuery>
            <AnimIn>
                <Typography>
                    <div className={styles.container}>
                        <div className={styles.donationBox}>
                            <MediaQuery maxWidth={1022}>
                                <Form.Row>
                                    <Form.RowColumns columnsMobile={1} columnsTablet={1}>
                                        <Form.RowColumn size={1}>
                                            <div className={styles.donation}>
                                                <div className={styles.summaryBox}>
                                                    <div className={styles.donationAmount}>
                                                        Thank you {firstName}, for your donation.
                                                    </div>
                                                    <span className={styles.donationTitle}>
                                                        {currencyFormatter.format(amountToDonate)}
                                                    </span>
                                                </div>
                                            </div>
                                        </Form.RowColumn>
                                    </Form.RowColumns>
                                </Form.Row>
                            </MediaQuery>
                            <Form.Row>
                                <Form.RowColumns columnsMobile={1} columnsTablet={1}>
                                    <Form.RowColumn size={0.6}>
                                        <Form.Row>
                                            <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                                <Form.RowColumn size={1}>
                                                    <h2 className={styles.title}>
                                                        Credit card information
                                                    </h2>
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                                <Form.RowColumn size={1}>
                                                    <Form.Field
                                                        defaultValue={values.nameOnCard || null}
                                                        onChange={value => handleFieldChange('nameOnCard', value)}
                                                        type='text'
                                                        placeholder={'Name on credit card'}
                                    />
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.RowColumns columnsTablet={2} columnsMobile={1}>
                                                <Form.RowColumn size={0.5}>
                                                    <Form.Field
                                                        defaultValue={values.cardType || null}
                                                        onChange={value => handleFieldChange('cardType', value)}
                                                        type='text'
                                                        placeholder={'Credit card type'}
                                    />
                                                </Form.RowColumn>

                                                <Form.RowColumn size={0.5}>
                                                    <Form.Field
                                                        defaultValue={values.cardNumber || null}
                                                        onChange={value => handleFieldChange('cardNumber', value)}
                                                        type='number'
                                                        placeholder={'Credit card number'}
                                    />
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.RowColumns columnsTablet={3} columnsMobile={1}>
                                                <Form.RowColumn size={0.3}>
                                                    <Form.Field
                                                        defaultValue={values.expMonth || null}
                                                        onChange={value => handleFieldChange('expMonth', value)}
                                                        type='number'
                                                        placeholder={'Exp. month'}
                                    />
                                                </Form.RowColumn>

                                                <Form.RowColumn size={0.3}>
                                                    <Form.Field
                                                        defaultValue={values.expYear || null}
                                                        onChange={value => handleFieldChange('expYear', value)}
                                                        type='number'
                                                        placeholder={'Exp. year'}
                                    />
                                                </Form.RowColumn>

                                                <Form.RowColumn size={0.3}>
                                                    <Form.Field
                                                        defaultValue={values.cvv || null}
                                                        onChange={value => handleFieldChange('cvv', value)}
                                                        type='number'
                                                        placeholder={'CVV'}
                                    />
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                                <Form.RowColumn size={1}>
                                                    <h2 className={styles.title}>
                                                        Billing address
                                                    </h2>
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                                <Form.RowColumn>
                                                    <span className={styles.checkbox}>
                                                        <Form.Checkbox
                                                            defaultChecked={values.sameAsContact}
                                                            onChange={() => {
                                                                if (!values.sameAsContact) {
                                                                    handleFieldChange('sameAsContact', true, () => {
                                                                        handleFieldChange('addressBilling', values.addressLine1, () => {
                                                                            handleFieldChange('cityBilling', values.city, () => {
                                                                                handleFieldChange('stateBilling', values.province, () => {
                                                                                    handleFieldChange('postalCodeBilling', values.postalCode)
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                } else {
                                                                    handleFieldChange('sameAsContact', false, () => {
                                                                        handleFieldChange('addressBilling', '', () => {
                                                                            handleFieldChange('cityBilling', '', () => {
                                                                                handleFieldChange('stateBilling', '', () => {
                                                                                    handleFieldChange('postalCodeBilling', '')
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                }
                                                            }}
                                        />
                                                        <p>{'Same as contact information provided.'}</p>
                                                    </span>
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                                <Form.RowColumn size={1}>
                                                    <Form.Field
                                                        defaultValue={initialValues.addressBilling}
                                                        value={values.addressBilling}
                                                        onChange={value => handleFieldChange('addressBilling', value)}
                                                        type='text'
                                                        placeholder={'Address'}
                                    />
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.RowColumns columnsTablet={3} columnsMobile={1}>
                                                <Form.RowColumn size={0.3}>
                                                    <Form.Field
                                                        defaultValue={initialValues.cityBilling}
                                                        value={values.cityBilling}
                                                        onChange={value => handleFieldChange('cityBilling', value)}
                                                        type='text'
                                                        placeholder={'City'}
                                    />
                                                </Form.RowColumn>

                                                <Form.RowColumn size={0.3}>
                                                    <Form.Field
                                                        defaultValue={initialValues.stateBilling}
                                                        value={values.stateBilling}
                                                        onChange={value => handleFieldChange('stateBilling', value)}
                                                        type='text'
                                                        placeholder={'State'}
                                    />
                                                </Form.RowColumn>

                                                <Form.RowColumn size={0.3}>
                                                    <Form.Field
                                                        defaultValue={initialValues.postalCodeBilling}
                                                        value={values.postalCodeBilling}
                                                        onChange={value => handleFieldChange('postalCodeBilling', value)}
                                                        type='text'
                                                        placeholder={'Zip Code'}
                                    />
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>

                                    </Form.RowColumn>

                                    <MediaQuery minWidth={1023}>
                                        <Form.RowColumn size={0.4}>
                                            <Form.Row>
                                                <Form.RowColumns columnsTablet={4} columnsMobile={2}>
                                                    <Form.RowColumn size={1} className={styles.row}>
                                                        <div className={styles.donation}>
                                                            <div className={styles.summaryBox}>
                                                                <div className={styles.donationTitle}>Donation amount</div>
                                                                <span className={styles.donationAmount}>{currencyFormatter.format(amountToDonate)}</span>
                                                            </div>

                                                            <div className={styles.summaryBox}>
                                                                <div className={styles.donationTitle}>Donation applied to</div>
                                                                {designations.map(designation => (
                                                                    <span className={styles.donationAmount}>{designation}</span>
                                                                ))}
                                                            </div>

                                                            <div className={styles.summaryBox}>
                                                                <div className={styles.donationTitle}>Donation Frequency</div>
                                                                <span className={styles.donationAmount}>{t(`donationType.option.${donationType}`)}</span>
                                                            </div>

                                                            <div className={styles.summaryBox}>
                                                                <div className={styles.donationTitle}>{firstName} {lastName}</div>
                                                                <span className={styles.donationAmount}>{addressLine1}</span>
                                                                <span className={styles.donationAmount}>
                                                                    {`${city} ${province} ${postalCode}`}
                                                                </span>
                                                                <br />
                                                                <span className={styles.donationAmount}>{mobilePhone}</span>
                                                                <span className={styles.donationAmount}>j{email}</span>
                                                            </div>
                                                        </div>
                                                    </Form.RowColumn>
                                                </Form.RowColumns>
                                            </Form.Row>
                                        </Form.RowColumn>
                                    </MediaQuery>
                                </Form.RowColumns>
                            </Form.Row>

                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={1}>
                                        <div className={styles.btnContainer}>
                                            <Form.Submit
                                                isLoading={isLoading}
                                                label={t('continue')}
                                                style={{
                                                    background: theme.background,
                                                    color: theme.color
                                                }}
                                    />
                                        </div>
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>
                        </div>
                    </div>
                </Typography>
            </AnimIn>
        </>
    )
}

Step6.defaultProps = {
    values: {
        nameOnCard: null,
        creditCardNumber: null,
        cardType: null,
        cardExpiry: null,
        cvv: null,
        billingAddress: null,
        billingSuiteAptNum: null,
        billingCity: null,
        billingState: null,
        billingZipCode: null,
        addres: null,
        zip: null,
        city: null,
        state: null,
        suiteAptNum: null
    }
}

export default withRouter(withForm()(withTheme(supportedThemes)(Step6)))
